<template>
  <div id="internalAuditPlan">
    <el-dialog
      :title="internalAuditPlanFormTitle"
      width="1200px"
      :visible.sync="internalAuditPlanDialogVisible"
      :close-on-click-modal="false"
      @close="internalAuditPlanDialogClose"
    >
      <el-form
        ref="internalAuditPlanFormRef"
        :model="internalAuditPlanForm"
        :rules="internalAuditPlanFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核目的" prop="purpose">
              <el-input v-model="internalAuditPlanForm.purpose" placeholder="请输入审核目的" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审核依据" prop="auditBasis">
              <el-input v-model="internalAuditPlanForm.auditBasis" placeholder="请输入审核依据" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核范围" prop="auditScope">
              <el-input v-model="internalAuditPlanForm.auditScope" placeholder="请输入审核范围" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审核时间" prop="auditTime">
              <el-date-picker v-model="internalAuditPlanForm.auditTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="受审部门及负责人" prop="deptPrincipal">
              <el-input v-model="internalAuditPlanForm.deptPrincipal" placeholder="请输入受审部门及负责人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审核组长" prop="auditTeamLeader">
              <el-input v-model="internalAuditPlanForm.auditTeamLeader" placeholder="请输入审核组长" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核员" prop="auditor">
              <el-input v-model="internalAuditPlanForm.auditor" placeholder="请输入审核员" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="internalAuditPlanFormTitle !== '内部审核实施计划详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="auditPlanActivityList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="activityDate"
              title="活动日期"
              :edit-render="{name: '$input', props: {type: 'date'}}"
            />
            <vxe-table-column
              field="auditTime"
              title="审核时间"
              :edit-render="{name: '$input', props: {type: 'datetime'}}"
            />
            <vxe-table-column
              field="groups"
              title="组别"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="dept"
              title="受审部门"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="clause"
              title="审核活动及涉及的标准条款"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column v-if="internalAuditPlanFormTitle !== '年度内部审核计划详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="拟制人" prop="editor">
              <el-input v-model="internalAuditPlanForm.editor" placeholder="请输入拟制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="拟制时间" prop="editTime">
              <el-date-picker v-model="internalAuditPlanForm.editTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="internalAuditPlanForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审核时间" prop="reviewDate">
              <el-date-picker v-model="internalAuditPlanForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="internalAuditPlanForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批准日期" prop="approveTime">
              <el-date-picker v-model="internalAuditPlanForm.approveTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="internalAuditPlanDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="internalAuditPlanFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="internalAuditPlanPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="purpose" label="审核目的" />
      <el-table-column prop="auditBasis" label="审核依据" />
      <el-table-column prop="auditScope" label="审核范围" />
      <el-table-column prop="auditTime" label="审核时间" />
      <el-table-column prop="deptPrincipal" label="受审部门及负责人" />
      <el-table-column prop="auditTeamLeader" label="审核组长" />
      <el-table-column prop="auditor" label="审核员" />
      <el-table-column prop="editor" label="拟制人" />
      <el-table-column prop="editTime" label="拟制时间" />
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column prop="reviewDate" label="审核日期" />
      <el-table-column prop="approver" label="批准人" />
      <el-table-column prop="approveTime" label="批准时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="internalAuditPlanPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInternalAuditPlan, deleteInternalAuditPlan, updateInternalAuditPlan, selectInternalAuditPlanInfo, selectInternalAuditPlanList } from '@/api/quality/internalAuditPlan'

export default {
  data () {
    return {
      internalAuditPlanDialogVisible: false,
      internalAuditPlanFormTitle: '',
      internalAuditPlanForm: {
        id: '',
        purpose: '',
        auditBasis: '',
        auditScope: '',
        auditTime: '',
        deptPrincipal: '',
        auditTeamLeader: '',
        auditor: '',
        editor: '',
        editTime: '',
        reviewer: '',
        reviewDate: '',
        approver: '',
        approveTime: '',
        auditPlanActivityJson: ''
      },
      internalAuditPlanFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      internalAuditPlanPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dept: ''
      },
      auditPlanActivityList: []
    }
  },
  created () {
    selectInternalAuditPlanList(this.searchForm).then(res => {
      this.internalAuditPlanPage = res
    })
  },
  methods: {
    internalAuditPlanDialogClose () {
      this.$refs.internalAuditPlanFormRef.resetFields()
      this.auditPlanActivityList = []
      this.internalAuditPlanForm.id = ''
    },
    internalAuditPlanFormSubmit () {
      if (this.internalAuditPlanFormTitle === '内部审核实施计划详情') {
        this.internalAuditPlanDialogVisible = false
        return
      }
      this.$refs.internalAuditPlanFormRef.validate(async valid => {
        if (valid) {
          this.internalAuditPlanForm.auditPlanActivityJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.internalAuditPlanFormTitle === '新增内部审核实施计划') {
            await addInternalAuditPlan(this.internalAuditPlanForm)
          } else if (this.internalAuditPlanFormTitle === '修改内部审核实施计划') {
            await updateInternalAuditPlan(this.internalAuditPlanForm)
          }
          this.internalAuditPlanPage = await selectInternalAuditPlanList(this.searchForm)
          this.internalAuditPlanDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.internalAuditPlanFormTitle = '新增内部审核实施计划'
      this.internalAuditPlanDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInternalAuditPlan(row.id)
        if (this.internalAuditPlanPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.internalAuditPlanPage = await selectInternalAuditPlanList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.internalAuditPlanFormTitle = '修改内部审核实施计划'
      this.internalAuditPlanDialogVisible = true
      this.selectInternalAuditPlanInfoById(row.id)
    },
    handleInfo (index, row) {
      this.internalAuditPlanFormTitle = '内部审核实施计划详情'
      this.internalAuditPlanDialogVisible = true
      this.selectInternalAuditPlanInfoById(row.id)
    },
    selectInternalAuditPlanInfoById (id) {
      selectInternalAuditPlanInfo(id).then(res => {
        this.internalAuditPlanForm.id = res.id
        this.internalAuditPlanForm.purpose = res.purpose
        this.internalAuditPlanForm.auditBasis = res.auditBasis
        this.internalAuditPlanForm.auditScope = res.auditScope
        this.internalAuditPlanForm.auditTime = res.auditTime
        this.internalAuditPlanForm.deptPrincipal = res.deptPrincipal
        this.internalAuditPlanForm.auditTeamLeader = res.auditTeamLeader
        this.internalAuditPlanForm.auditor = res.auditor
        this.internalAuditPlanForm.editor = res.editor
        this.internalAuditPlanForm.editTime = res.editTime
        this.internalAuditPlanForm.reviewer = res.reviewer
        this.internalAuditPlanForm.reviewDate = res.reviewDate
        this.internalAuditPlanForm.approver = res.approver
        this.internalAuditPlanForm.approveTime = res.approveTime

        this.auditPlanActivityList = res.auditPlanActivityList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInternalAuditPlanList(this.searchForm).then(res => {
        this.internalAuditPlanPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInternalAuditPlanList(this.searchForm).then(res => {
        this.internalAuditPlanPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInternalAuditPlanList(this.searchForm).then(res => {
        this.internalAuditPlanPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
