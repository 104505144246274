import axios from '@/common/axios'
import qs from 'qs'

export function addInternalAuditPlan (data) {
  return axios({
    method: 'post',
    url: '/quality/auditPlan/add',
    data: qs.stringify(data)
  })
}

export function deleteInternalAuditPlan (id) {
  return axios({
    method: 'delete',
    url: '/quality/auditPlan/delete/' + id
  })
}

export function updateInternalAuditPlan (data) {
  return axios({
    method: 'put',
    url: '/quality/auditPlan/update',
    data: qs.stringify(data)
  })
}

export function selectInternalAuditPlanInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/auditPlan/info/' + id
  })
}

export function selectInternalAuditPlanList (query) {
  return axios({
    method: 'get',
    url: '/quality/auditPlan/list',
    params: query
  })
}
